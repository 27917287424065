import "core-js/stable";
import "regenerator-runtime/runtime";
import Glide from "@glidejs/glide";
import ResponsiveAutoHeight from "responsive-auto-height";
import { initScrollReveal } from "./scroll-reveal";
import GLightbox from "glightbox";
import Player from "@vimeo/player";
import {
    displaySiteOverlay,
    getParentElementWithTagAndClass,
    getParentElementWithClass,
    hideSiteOverlay,
    scrollTo,
    getYoutubeIdFromVideoUrl
} from "./utilities";

/*==========================================
 Variables
===========================================*/

const htmlElement = document.querySelector("html");
const bodyElement = document.body;
const headerElement = document.querySelector("header#o-header");
const siteOverlayElement = document.querySelector("#a-site-overlay");
const preLoaderOverlayElement = document.querySelector("#a-pre-load-overlay");

const overlaySearchClass = "v-search-open";
const overlayHamburgerMenuClass = "v-hamburger-menu-open";

/*==========================================
 Click Events Listeners
===========================================*/

// Kieran - used single event listener on the body, importing functions from utilities and using them here, need time to read through all this and pick it up

bodyElement.addEventListener("click", evt => {
    const clickedElement = evt.target;

    const submenuButtonElement = getParentElementWithTagAndClass(
        clickedElement,
        "button",
        "js-submenu-button"
    );

    if (submenuButtonElement) {
        submenuActions(submenuButtonElement);
    }

    const siteOverlayElement = getParentElementWithTagAndClass(
        clickedElement,
        "div",
        "a-site-overlay"
    );

    if (siteOverlayElement) {
        closeHamburger();
        closeSearch();
    }

    const searchButton = getParentElementWithTagAndClass(
        clickedElement,
        "button",
        "js-search-button"
    );

    if (searchButton) {
        searchActions(searchButton);
    }

    const anchorLinkButton = getParentElementWithTagAndClass(
        clickedElement,
        "button",
        "a-anchor-link-button"
    );

    if (anchorLinkButton) {
        anchorLinkButtonActions(anchorLinkButton);
    }

    const scrollToLink = getParentElementWithClass(
        clickedElement,
        "js-scroll-to"
    );

    if (scrollToLink) {
        const scrollToElement = document.querySelector(
            scrollToLink.getAttribute("href")
        );
        if (scrollToElement) {
            scrollTo(scrollToElement);
        }
    }
});

bodyElement.addEventListener("mouseenter", evt => {});

/*==========================================
 Init plugins
===========================================*/

/*==========================================
Pre Loader
===========================================*/

const ready = fn => {
    if (document.readyState != "loading") {
        fn();
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
};

const hidePreloader = () => {
    preLoaderOverlayElement.classList.add("v-hidden");
    htmlElement.style.fontSize = null;

    setTimeout(() => {
        initScrollReveal();

        setTimeout(() => {
            preLoaderOverlayElement.classList.add("display-none");
        }, 200);
    }, 400);
};

setTimeout(() => {
    ready(hidePreloader);
}, 300);

/*==========================================
Equal Height
===========================================*/

const runMatchHeight = () => {
    const elements = document.querySelectorAll(".match-height");
    if (elements && elements.length) {
        const m = new ResponsiveAutoHeight(".match-height");
    }
};

runMatchHeight();

/*==========================================
Hamburger
===========================================*/

const hamburgerButtonElement = document.querySelector(".js-hamburger-button");

const navigationBlock = headerElement.querySelector(".m-navigation-block");

if (hamburgerButtonElement) {
    hamburgerButtonElement.addEventListener("click", () => {
        hamburgerActions();
    });
}

const hamburgerActions = () => {
    if (hamburgerButtonElement.classList.contains("v-active")) {
        closeHamburger();
    } else {
        openHamburger();
    }
};

const openHamburger = () => {
    closeSearch();
    hamburgerButtonElement.classList.add("v-active");
    navigationBlock.classList.add("v-open");
    displaySiteOverlay(overlayHamburgerMenuClass);
};

const closeHamburger = () => {
    hamburgerButtonElement.classList.remove("v-active");
    navigationBlock.classList.remove("v-open");
    hideSiteOverlay(overlayHamburgerMenuClass);
};

/*==========================================
Search
===========================================*/

const searchButtonElementSelector = "button.js-search-button";
const searchButtons = document.querySelectorAll(searchButtonElementSelector);
const searchBlock = headerElement.querySelector(".m-search-block");
const searchBlockForm = searchBlock.querySelector("form.block-inner");
const searchTextInput = searchBlock.querySelector("input[type=text]");
const searchBlockRadioButtons = searchBlock.querySelector(
    ".block-radio-buttons"
);
const searchBlockFormLotsUrl = searchBlockForm.getAttribute(
    "data-lots-search-url"
);
const searchBlockFormOtherUrl = searchBlockForm.getAttribute(
    "data-other-search-url"
);

const searchActions = () => {
    if (bodyElement.classList.contains(overlaySearchClass)) {
        closeSearch();
    } else {
        openSearch();
    }
};

const openSearch = () => {
    closeHamburger();
    changeSearchButtonClasses(true);
    searchBlock.classList.add("v-open");
    displaySiteOverlay(overlaySearchClass);

    setTimeout(() => {
        searchTextInput.focus();
    }, 300);
};

const closeSearch = () => {
    changeSearchButtonClasses(false);
    searchBlock.classList.remove("v-open");
    hideSiteOverlay(overlaySearchClass);
};

const changeSearchButtonClasses = (addClass = true) => {
    if (addClass) {
        searchButtons.forEach(searchButton => {
            searchButton.classList.add("v-active");
        });
    } else {
        searchButtons.forEach(searchButton => {
            searchButton.classList.remove("v-active");
        });
    }
};

searchBlockRadioButtons.addEventListener("change", evt => {
    const checkedRadio = searchBlockRadioButtons.querySelector(
        "input[type=radio]:checked"
    );

    if (checkedRadio.value === "lots") {
        searchTextInput.setAttribute("placeholder", "Search for lots");
        searchBlockForm.setAttribute("action", searchBlockFormLotsUrl);
    } else {
        searchTextInput.setAttribute("placeholder", "Search the site");
        searchBlockForm.setAttribute("action", searchBlockFormOtherUrl);
    }

    searchTextInput.focus();
});

/*==========================================
Submenu
===========================================*/

const submenuActions = submenuButton => {
    const submenuParent = getParentElementWithTagAndClass(
        submenuButton,
        "div",
        "navigation-item"
    );

    if (submenuParent.classList.contains("v-open")) {
        closeSubmenu(submenuParent);
    } else {
        openSubmenu(submenuParent);
    }
};

// Kieran - I can see why splitting everything up into functions that can be reused is useful, but it does intially consfuse me more and take longer to read/get used to.

const openSubmenu = submenuParent => {
    closeAllSubmenus();
    submenuParent.classList.add("v-open");
};

const closeSubmenu = submenuParent => {
    submenuParent.classList.remove("v-open");
};

const closeAllSubmenus = () => {
    const allSubmenus = [
        ...headerElement.querySelectorAll(".navigation-item.v-has-children")
    ];
    allSubmenus.forEach(submenu => submenu.classList.remove("v-open"));
};

/*==========================================
Glide
===========================================*/

const sliders = document.querySelectorAll(".glide.js-slider");
const sliders2 = document.querySelectorAll(".js-slider-new");
const carousels = document.querySelectorAll(".glide.js-carousel");

const updateSliderProgress = (progressContainer, currentSlideIndex, count) => {
    let index = currentSlideIndex + 1;
    const percentage = `${(index / count) * 100}%`;

    progressContainer.style.width = percentage;
};

const updateSliderControls = (bullets, currentSlideIndex) => {
    bullets.forEach((bullet, index) => {
        if (index === currentSlideIndex) {
            bullet.classList.add("v-active");
        } else {
            bullet.classList.remove("v-active");
        }
    });
};

if (sliders) {
    sliders.forEach(slider => {
        const sliderCount = slider.querySelectorAll(".glide__slide").length;
        const progressIndicator = slider.querySelector(
            ".m-glide-controls-block .progress-container span"
        );
        const autoplayValue = slider.getAttribute("data-autoplay");

        const glideSlider = new Glide(`#${slider.getAttribute("id")}`, {
            type: "slider",
            hoverpause: false,
            autoplay: 6000,
            animationDuration: 2000
        });

        if (progressIndicator) {
            glideSlider.on("move", () => {
                updateSliderProgress(
                    progressIndicator,
                    glideSlider.index,
                    sliderCount
                );
            });
        }

        glideSlider.mount();
    });
}

if (sliders2) {
    sliders2.forEach(slider => {
        const bullets = slider.querySelectorAll(
            ".m-glide-controls-block .glide__bullet"
        );
        const autoplayValue = slider.getAttribute("data-autoplay");

        const glideSlider = new Glide(`#${slider.getAttribute("id")}`, {
            type: "slider",
            hoverpause: false,
            autoplay: autoplayValue,
            animationDuration: 1000
        });

        if (bullets) {
            glideSlider.on("move", () => {
                updateSliderControls(bullets, glideSlider.index);
            });
        }

        glideSlider.mount();
    });
}

if (carousels) {
    carousels.forEach(carousel => {
        let initCarousel = true;
        const ww = window.innerWidth;
        const carouselCount = carousel.querySelectorAll(".glide__slide").length;
        const progressIndicator = carousel.querySelector(
            ".m-glide-controls-block .progress-container span"
        );

        const glideCarousel = new Glide(`#${carousel.getAttribute("id")}`, {
            type: "carousel",
            perView: 4,
            autoplay: false,
            breakpoints: {
                1023: {
                    perView: 2
                },
                600: {
                    perView: 1
                }
            }
        });

        if (progressIndicator) {
            glideCarousel.on("move", () => {
                updateSliderProgress(
                    progressIndicator,
                    glideCarousel.index,
                    carouselCount
                );
            });
        }

        if (initCarousel) {
            glideCarousel.mount();
        }
    });
}

/*==========================================
Anchor Link Button
===========================================*/

const anchorLinkButtonActions = anchorLinkButton => {
    const navigationBlock = getParentElementWithTagAndClass(
        anchorLinkButton,
        "div",
        "block-navigation"
    );

    if (anchorLinkButton.classList.contains("v-active")) {
        anchorLinkButton.classList.remove("v-active");
        navigationBlock.classList.remove("v-open");
    } else {
        anchorLinkButton.classList.add("v-active");
        navigationBlock.classList.add("v-open");
    }
};

/*==========================================
Vimeo
===========================================*/

const initVideoActions = () => {
    const videoPlayers = document.querySelectorAll(".block-video");

    if (videoPlayers) {
        videoPlayers.forEach((videoPlayer, index) => {
            const mediaBlock = getParentElementWithTagAndClass(
                videoPlayer,
                "div",
                "m-media-block"
            );
            const videoPlayButton = mediaBlock.querySelector(".js-play-video");
            const videoId = videoPlayer.getAttribute("id");
            const videoUrl = videoPlayer.getAttribute("data-video-url");

            const isYouTube =
                videoUrl.indexOf("youtu.be") > -1 ||
                videoUrl.indexOf("youtube") > -1;

            if (isYouTube) {
                const videoUrlId = getYoutubeIdFromVideoUrl(videoUrl);
                let videoSrc = `https://www.youtube.com/embed/${videoUrlId}`;
                let iframe = mediaBlock.querySelector("iframe");

                if (!iframe || typeof iframe === "undefined") {
                    const createdIframe = document.createElement("iframe");
                    createdIframe.setAttribute("width", "100%");
                    createdIframe.setAttribute("frameborder", "0");
                    createdIframe.setAttribute("title", "Youtube Video");
                    createdIframe.setAttribute("allow", "autoplay");
                    createdIframe.setAttribute("src", videoSrc);
                    iframe = createdIframe;
                    mediaBlock
                        .querySelector(".block-video")
                        .appendChild(createdIframe);
                }

                videoPlayButton.addEventListener("click", evt => {
                    mediaBlock.classList.add("v-show");
                    iframe.setAttribute("src", `${videoSrc}?autoplay=1`);
                    setTimeout(() => {
                        mediaBlock.classList.add("v-visible");
                    }, 300);
                });
            } else {
                const vimeoPlayer = new Player(videoId, {
                    url: videoUrl,
                    width: 640
                });

                videoPlayButton.addEventListener("click", evt => {
                    mediaBlock.classList.add("v-show");
                    setTimeout(() => {
                        mediaBlock.classList.add("v-visible");

                        vimeoPlayer.play();
                    }, 300);
                });
            }
        });
    }
};

initVideoActions();

/*==========================================
Newsletter Signup Event Tracking
===========================================*/

const newsletterSignupForm = document.querySelector(
    "section.o-newsletter-signup-section form#subForm"
);

if (newsletterSignupForm) {
    newsletterSignupForm.addEventListener("submit", evt => {
        window.wrSendTrackingEvent(
            "Newsletters",
            window.location.href,
            "Sign up"
        );
    });
}

const paymentsForm = document.querySelector(
    "section.o-payments-form-section form"
);

if (paymentsForm) {
    paymentsForm.addEventListener("submit", evt => {
        window.wrSendTrackingEvent(
            "Payment Form",
            window.location.href,
            "Submitted"
        );
    });
}

/*==========================================
Add extra classes with JS
===========================================*/

// var hasTitle = document.querySelectorAll(".m-text-block-js");

// console.log(hasTitle);

// if (hasTitle && hasTitle.length) {
//     if (hasTitle) {
//         hasTitle.forEach(e => {
//             const parentDiv = e.closest(".row");
//             console.log(hasTitle);
//             parentDiv.classList.add("v-center");
//         });
//     }
// }
